<template>
    <div class="flex flex-col gap-10 p-4">
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§1 Administracja danych osobowych</h2>
            <ol class="list-decimal list-outside pl-4 text-justify font-normal flex flex-col gap-2">
                <li>Administratorem danych osobowych jest Adam Rogalski, prowadzący działalność gospodarczą pod firmą
                    Upadłość Konsumencka - Kancelaria Adam Rogalski, Nadbrzeżna 2, 43-300 Bielsko-Biała. Działalność
                    gospodarcza jest wpisana do Centralnej Ewidencji i Informacji o Działalności Gospodarczej pod
                    numerem NIP: 4990051434, REGON 122449180.
                </li>
                <li>Kontakt z osobą nadzorującą przetwarzanie danych osobowych w organizacji jest możliwy drogą
                    elektroniczną pod adresem e-mail: biuro.arkancelaria@gmail.com, pisemnie na adres Administratora lub
                    telefonicznie pod numerem telefonu 534 123 123.
                </li>
                <li>Niniejsza Polityka zawiera zasady dotyczące przetwarzania danych osobowych przez Administratora w
                    Serwisie Internetowym, w tym podstawy, cele i zakres przetwarzania danych osobowych oraz prawa osób,
                    których dane dotyczą.
                </li>
                <li>Dane osobowe przetwarzane są przez Administratora zgodnie z obowiązującymi przepisami prawa, w
                    szczególności zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
                    kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
                    sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie
                    o ochronie danych) Oficjalny tekst Rozporządzenia RODO:
                    <a style="color: var(--p-primary-500);text-decoration:underline;"
                        href="https://eur-lex.europa.eu/legal-content/PL/TXT/?uri=CELEX%3A32016R0679">
                        https://eur-lex.europa.eu/legal-content/PL/TXT/?uri=CELEX%3A32016R0679
                    </a>
                </li>
                <li>Uprawnienia Użytkownika nie są bezwzględne i nie przysługują w odniesieniu do wszystkich czynności
                    przetwarzania danych osobowych.
                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§2 Definicje</h2>
            <ol class="list-decimal list-outside pl-4 text-justify font-normal flex flex-col gap-2">
                <li>Administrator - Adam Rogalski, prowadzący działalność gospodarczą pod firmą Upadłość Konsumencka -
                    Kancelaria Adam Rogalski, Nadbrzeżna 2, 43-300 Bielsko-Biała. Działalność gospodarcza jest wpisana
                    do Centralnej Ewidencji i Informacji o Działalności Gospodarczej pod numerem NIP: 4990051434, REGON
                    122449180.
                </li>
                <li>Dane osobowe - informacje o osobie fizycznej zidentyfikowanej lub możliwej do zidentyfikowania
                    poprzez jeden bądź kilka szczególnych czynników określających fizyczną, fizjologiczną, genetyczną,
                    psychiczną, ekonomiczną, kulturową lub społeczną tożsamość, w tym IP urządzenia, identyfikator
                    internetowy oraz informacje gromadzone za pośrednictwem plików cookie oraz innej podobnej
                    technologii.
                </li>
                <li>Polityka - niniejsza Polityka prywatności.
                </li>
                <li>RODO / Rozporządzenie RODO - rozporządzenie parlamentu Europejskiego i Rady (UE) 2016/679 z
                    27.04.2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
                    sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.
                </li>
                <li>Serwis - serwis internetowy prowadzony przez Administratora pod adresem upadlosc-ar-expert.pl.
                </li>
                <li>Użytkownik - każda osoba fizyczna odwiedzająca Serwis lub korzystająca z jednej albo kilku usług czy
                    funkcjonalności opisanych w Polityce.
                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§3 Bezpieczeństwo</h2>
            <div class="flex flex-col text-justify font-normal">
                <span>Administrator wdrożył odpowiednie środki techniczne i organizacyjne, które zapewniają
                    bezpieczeństwo
                    przetwarzania danych osobowych, a w szczególności jest odpowiedzialny i zapewnia, że zbierane przez
                    niego dane są:</span>
                <ul class="list-disc list-outside pl-4 text-justify font-normal">
                    <li>przetwarzane zgodnie z prawem;
                    </li>
                    <li>zbierane dla oznaczonych, zgodnych z prawem celów i niepoddawane dalszemu przetwarzaniu
                        niezgodnemu z tymi celami;
                    </li>
                    <li>merytorycznie poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane;
                    </li>
                    <li>przechowywane w postaci umożliwiającej identyfikację osób, których dotyczą, nie dłużej niż
                        jest
                        to niezbędne do osiągnięcia celu przetwarzania oraz
                    </li>
                    <li>przetwarzane w sposób zapewniający odpowiednie bezpieczeństwo danych osobowych, w tym ochronę
                        przed niedozwolonym lub niezgodnym z prawem przetwarzaniem oraz przypadkową utratą, zniszczeniem
                        lub
                        uszkodzeniem, za pomocą odpowiednich środków technicznych lub organizacyjnych.
                    </li>
                </ul>
            </div>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§4 Cele i podstawy prawne przetwarzania danych</h2>
            <ol class="list-decimal list-outside pl-4 text-justify font-normal flex flex-col gap-2">
                <li>Na podstawie artykuł 6 ust. 1 lit. a) Rozporządzenia RODO (zgody) dane osobowe będą przetwarzane w
                    celach:
                    <ul class="list-disc list-outside pl-4 text-justify font-normal">
                        <li>moderowania treści w Serwisie,
                        </li>
                        <li>zapisywania danych w plikach cookie, jak również wykorzystywaniu plików cookie do
                            prawidłowego
                            funkcjonowania Serwisu,
                        </li>
                        <li>kontaktu za pośrednictwem narzędzi porozumiewania się na odległość, w szczególności:
                            telefonicznego, mailowego lub aplikacji.
                        </li>
                    </ul>
                </li>
                <li>Na podstawie artykułu 6 ust 1 lit. b) Rozporządzenia RODO (wykonywania umowy) dane osobowe będą
                    przetwarzane w celach:
                    <ul class="list-disc list-outside pl-4 text-justify font-normal">
                        <li>Wykonywania umowy sprzedaży lub umowy o świadczenia Usługi lub podjęcia działań na żądanie
                            osoby,
                            której dane dotyczą przed zawarciem wskazanej umowy lub
                            po jej zawarciu, w szczególności: prawo do rękojmi, rozpatrzenia
                        </li>
                        <li>Reklamacji lub odstąpienia od umowy zawartej na odległość

                        </li>
                    </ul>
                </li>
                <li>Na podstawie artykułu 6 ust 1 lit. c) Rozporządzenia RODO (obowiązek prawny ciążący na
                    Administratorze) dane osobowe będą przetwarzane w celach:
                    <ul class="list-disc list-outside pl-4 text-justify font-normal">
                        <li>Wystawianie i przechowywanie faktur, rachunków lub spełnienia innych obowiązków wynikających
                            z
                            przepisów podatkowo-rachunkowych, (obowiązek archiwizacyjny dot. dokumentów księgowych).

                        </li>
                        <li>Tworzenie rejestrów i innej dokumentacji nakazanej przepisami RODO.
                        </li>
                    </ul>
                </li>
                <li>Na podstawie artykułu 6 ust 1 lit. f) Rozporządzenia RODO (prawnie uzasadniony interes
                    Administratora) dane osobowe będą przetwarzane w celach:
                    <ul class="list-disc list-outside pl-4 text-justify font-normal">
                        <li>Prawidłowej realizacji umowy, będą przetwarzane przez czas realizacji umowy i uprawnień z
                            niej
                            wynikających np. prawo do reklamacji. Podanie danych jest dobrowolne, ale i niezbędne.
                        </li>
                        <li>Zabezpieczenia bezpieczeństwa Serwisu, zarządzania Serwisem oraz jego prawidłowego
                            działania.
                        </li>
                        <li>Ustalenia roszczeń podnoszonych przez lub wobec Administratora.
                        </li>
                        <li>Kontaktu z Użytkownikiem.
                        </li>
                        <li>Obsługi Serwisu upadlosc-ar-expert.pl.
                        </li>
                        <li>Dane mogą być przekazywane do następujących odbiorców lub kategorii odbiorców danych
                            osobowych
                            tj. firmom kurierskim, operatorom pocztowym, kancelariom prawnym, firmom księgowym,
                            dostawcom i
                            serwisantom usług IT.
                        </li>
                    </ul>
                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§5 Profilowanie</h2>
            <span>Rozporządzenie RODO nakłada na Administratora obowiązek informowania o zautomatyzowanym podejmowaniu
                decyzji, w tym o profilowaniu, o którym mowa w art. 22 ust. 1 i 4 Rozporządzenia RODO, oraz -
                przynajmniej w tych przypadkach - istotne informacje o zasadach ich podejmowania, a także o
                znaczeniu i przewidywanych konsekwencjach takiego przetwarzania dla osoby, której dane dotyczą.
                Mając to na uwadze Administrator podaje w tym punkcie polityki prywatności informacje dotyczące
                możliwego profilowania.
                Administrator nie korzysta w Serwisie z profilowania do celów marketingowych wykorzystując podane
                przez Użytkownika dane osobowe.
            </span>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§6 Okres przetwarzania Danych osobowych</h2>
            <ol class="list-decimal list-outside pl-4 text-justify font-normal flex flex-col gap-2">
                <li>Okres przetwarzania danych przez Administratora zależy od rodzaju świadczonej usługi i celu
                    przetwarzania. Co do zasady dane przetwarzane są przez czas świadczenia usługi, do czasu wycofania
                    wyrażonej zgody lub zgłoszenia skutecznego sprzeciwu względem przetwarzania danych w przypadkach,
                    gdy podstawą prawną przetwarzania danych jest prawnie uzasadniony interes Administratora.
                </li>
                <li>Okres przetwarzania danych może być przedłużony w przypadku, gdy przetwarzanie jest niezbędne do
                    ustalenia i dochodzenia ewentualnych roszczeń lub obrony przed roszczeniami, a po tym czasie jedynie
                    w przypadku i w zakresie, w jakim będą wymagać tego przepisy prawa. Po upływie okresu przetwarzania
                    dane są nieodwracalnie usuwane lub zanonimizowane.
                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§7 Prawa Użytkownika</h2>
            <div class="flex flex-col text-justify font-normal">
                <span>Użytkownikowi w odniesieniu do swoich danych osobowych przysługują następujące prawa:</span>
                <ul class="list-disc list-outside pl-4 text-justify font-normal">
                    <li>dostęp do swoich danych osobowych,
                    </li>
                    <li>sprostowania danych osobowych w dowolnym momencie,
                    </li>
                    <li>usunięcia swoich danych osobowych w dowolnym momencie,
                    </li>
                    <li>otrzymania kopii swoich danych,
                    </li>
                    <li>ograniczenia przetwarzania danych osobowych,
                    </li>
                    <li>sprzeciwu wobec przetwarzania danych osobowych,
                    </li>
                    <li>przenoszenia danych osobowych,
                    </li>
                    <li>cofnięcia zgody; wycofanie zgody nie wpływa na zgodność z prawem przetwarzania dokonanego przed
                        jej cofnięciem,
                    </li>
                    <li>do wniesienia skargi do organu nadzorczego.
                    </li>
                </ul>
            </div>

        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§8 Odbiorcy danych osobowych</h2>
            <ol class="list-decimal list-outside pl-4 text-justify font-normal flex flex-col gap-2">
                <li>Administrator w celu prawidłowego prowadzenia Serwisu przekazuje dane osobowe Użytkownika innym
                    podmiotom zewnętrznym, w szczególności: Firma hostingowa, operatorzy płatności.
                </li>
                <li>Administrator zastrzega sobie prawo do ujawnienia danych osobowych w sytuacji, gdy będzie to wynikać
                    z obowiązujących przepisów prawa, w tym obowiązek przekazania informacji do właściwych organów
                    administracyjnych lub organów ścigania.
                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§9 Przekazywanie danych osobowych poza EOG</h2>
            <span>Poziom ochrony Danych osobowych poza Europejskim Obszarem Gospodarczym (EOG) różni się od tego zapewnianego
            przez prawo europejskie. Z tego powodu Administrator informuje, że nie przekazuje Danych osobowych poza
            EOG.</span>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§10 Bezpieczeństwo Danych osobowych</h2>
            <ol class="list-decimal list-outside pl-4 text-justify font-normal flex flex-col gap-2">
                <li>Administrator na bieżąco prowadzi analizę ryzyka w celu zapewnienia, że Dane osobowe przetwarzane są
                    przez niego w sposób bezpieczny. Poprzez swoje działanie zapewnia przede wszystkim, że dostęp do
                    danych mają jedynie osoby upoważnione i jedynie w zakresie, w jakim jest to niezbędne ze względu na
                    wykonywane przez nie zadania.
                </li>
                <li>Administrator zobowiązany jest do podejmowania wszelkich dozwolonych przepisami prawa działań, by
                    wszystkie operacje na Danych osobowych były rejestrowane i dokonywane jedynie przez uprawniony
                    podmiot.
                </li>
                <li>Administrator obowiązany jest również do tego, aby inne podmioty współpracujące z Administratorem
                    dawały gwarancję stosowania odpowiednich środków bezpieczeństwa w każdym przypadku, gdy przetwarzają
                    Dane osobowe na zlecenie Administratora.
                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§11 Zmiany Polityki prywatności</h2>
            <ol class="list-decimal list-outside pl-4 text-justify font-normal flex flex-col gap-2">
                <li>Polityka jest na bieżąco weryfikowana i aktualizowana.
                </li>
                <li>Aktualna wersja Polityki została przyjęta i obowiązuje od 22-01-2025.
                </li>
            </ol>
        </div>
    </div>
</template>

<script setup>
</script>