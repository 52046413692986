<template>
    <div class="flex flex-col gap-10 p-4">
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">Regulamin upadlosc-ar-expert.pl</h2>
            <ol class="list-decimal list-outside pl-8 text-justify font-normal flex flex-col gap-2">
                <li>Właścicielem Serwisu pod adresem upadlosc-ar-expert.pl jest Adam Rogalski, prowadzący działalność
                    gospodarczą pod firmą Upadłość Konsumencka -&nbsp;Kancelaria Adam Rogalski, Nadbrzeżna 2, <span
                        class="whitespace-nowrap">43-300 Bielsko-Biała</span>. Działalność gospodarcza jest wpisana do
                    Centralnej Ewidencji i&nbsp;Informacji o&nbsp;Działalności Gospodarczej pod numerem NIP: 4990051434,
                    REGON 122449180.
                </li>
                <li>Regulamin określa zasady korzystania z&nbsp;Serwisu oraz jego funkcjonalności oraz m.in. rodzaj
                    i&nbsp;zakres świadczonych usług, w&nbsp;tym usług elektronicznych świadczonych przez jej
                    właściciela, warunki i&nbsp;zasady składania zamówień, warunki techniczne, sposób zawierania
                    i&nbsp;rozwiązywania umów na odległość, zasady i&nbsp;terminy płatności oraz tryb postępowań
                    reklamacyjnych.
                </li>
                <li>Możesz skontaktować się z&nbsp;Właścicielem Serwisu pisząc na adres e-mail:
                    biuro.arkancelaria@gmail.com lub telefonicznie: 534 123 123.
                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§2 Definicje</h2>
            <ol class="list-decimal list-outside pl-8 text-justify font-normal flex flex-col gap-2">
                <li>Dzień roboczy –&nbsp;dzień tygodnia od poniedziałku do piątku, z&nbsp;wyłączeniem dni wolnych od
                    pracy.
                </li>
                <li>DSA -&nbsp;Rozporządzenie Parlamentu Europejskiego i&nbsp;Rady (UE) 2022/2065 z&nbsp;dnia 19
                    października 2022 r. w&nbsp;sprawie jednolitego rynku usług cyfrowych oraz zmiany dyrektywy
                    2000/31/WE (akt o&nbsp;usługach cyfrowych).
                </li>
                <li>Kodeks cywilny -&nbsp;ustawa kodeks cywilny z&nbsp;dnia 23 kwietnia 1964 r.
                </li>
                <li>Klient –&nbsp;osoba fizyczna posiadająca pełną zdolność do czynności prawnych,
                    a&nbsp;w&nbsp;wypadkach
                    przewidzianych przez przepisy powszechnie obowiązujące także osoba fizyczna posiadająca ograniczoną
                    zdolność do czynności prawnych, osoba prawna oraz jednostka organizacyjna nieposiadająca osobowości
                    prawnej, której ustawa przyznaje zdolność prawną –&nbsp;która zawarła lub zamierza zawrzeć umowę
                    z&nbsp;Właścicielem Serwisu.
                </li>
                <li>Konsument –&nbsp;osoba fizyczna dokonująca z&nbsp;przedsiębiorcą czynności prawnej niezwiązanej
                    bezpośrednio z&nbsp;jej działalnością gospodarczą lub zawodową (definicja na podstawie art. 22(1)
                    Kodeksu Cywilnego).
                </li>
                <li>Polityka prywatności -&nbsp;dokument określający zasady przetwarzania danych osobowych, dostępna pod
                    adresem <a style="color: var(--p-primary-500);text-decoration:underline;"
                        href="https://upadlosc-ar-expert.pl/data-policy-and-user-agreement">
                        https://upadlosc-ar-expert.pl/data-policy-and-user-agreement
                    </a>.
                </li>
                <li>Produkt –&nbsp;każda Usługa w&nbsp;rozumieniu art. 2&nbsp;pkt 3&nbsp;ustawy o&nbsp;przeciwdziałaniu
                    nieuczciwym praktykom rynkowym; Produkt ma charakter odpłatny, chyba że wskazano inaczej.
                </li>
                <li>Produkt elektroniczny –&nbsp;dostęp online do autorskiego oprogramowania, którą Klient otrzymuje
                    w&nbsp;ramach Zakupu.
                </li>
                <li>Przedsiębiorca –&nbsp;osoba fizyczna, osoba prawna i&nbsp;jednostka organizacyjna niebędąca osobą
                    prawną,
                    której odrębna ustawa przyznaje zdolność prawną, wykonująca we własnym imieniu działalność
                    gospodarczą, która korzysta z&nbsp;Serwisu.
                </li>
                <li>Przedsiębiorca na prawach konsumenta –&nbsp;osoba fizyczna, zawierająca umowę bezpośrednio związaną
                    z&nbsp;jej działalnością gospodarczą, gdy z&nbsp;treści tej umowy wynika, że umowa ta nie posiada
                    dla niej charakteru zawodowego, wynikającego w&nbsp;szczególności z&nbsp;przedmiotu wykonywanej
                    przez nią działalności gospodarczej, udostępnionego na podstawie przepisów o&nbsp;Centralnej
                    Ewidencji i&nbsp;Informacji o&nbsp;Działalności Gospodarczej.
                </li>
                <li>Regulamin –&nbsp;niniejszy Regulamin Serwisu określający zasady korzystania z&nbsp;Serwisu,
                    składania
                    zamówień oraz zasad realizacji zamówień przez Właściciela Serwisu.
                </li>
                <li>Serwis –&nbsp;strona upadlosc-ar-expert.pl, na której prowadzona jest sprzedaż Produktów przez
                    Właściciela Serwisu.
                </li>
                <li>Właściciel Serwisu -&nbsp;Adam Rogalski, prowadzący działalność gospodarczą pod firmą Upadłość
                    Konsumencka -&nbsp;Kancelaria Adam Rogalski, Nadbrzeżna 2, 43-300 Bielsko-Biała. Działalność
                    gospodarcza jest wpisana do Centralnej Ewidencji i&nbsp;Informacji o&nbsp;Działalności Gospodarczej
                    pod numerem NIP: 4990051434, REGON 122449180.
                </li>
                <li>Umowa –&nbsp;wzajemne uzgodnienia Właściciela Serwisu i&nbsp;Klienta określające wzajemne prawa oraz
                    obowiązki.
                </li>
                <li>Umowa zawarta na odległość –&nbsp;umowa zawarta bez jednoczesnej fizycznej obecności stron,
                    z&nbsp;wyłącznym wykorzystaniem jednego lub większej liczby środków porozumiewania się na odległość
                    do chwili zawarcia umowy włącznie.
                </li>
                <li>Usługa –&nbsp;to każda czynność zawierająca w&nbsp;sobie element niematerialności, która polega na
                    oddziaływaniu na Klienta lub jego przedmioty, które nie powoduje przeniesienia praw własności.
                </li>
                <li>Usługa elektroniczna –&nbsp;usługa świadczona drogą elektroniczną za pośrednictwem Serwisu; usługa
                    w&nbsp;rozumieniu art. 2&nbsp;pkt 4&nbsp;ustawy o&nbsp;świadczeniu usług drogą elektroniczną.
                </li>
                <li>Użytkownik –&nbsp;podmiot korzystający z&nbsp;Serwisu.
                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§3 Usługi elektroniczne w&nbsp;Serwisie internetowym</h2>
            <ol class="list-decimal list-outside pl-8 text-justify font-normal flex flex-col gap-2">
                <li>W&nbsp;Serwisie Internetowym dostępne są następujące Usługi elektroniczne: Rejestracja konta,
                    Uzupełnienie ankiety, Logowanie, płatny dostęp na okres jednego roku, do Produktu elektronicznego
                    w&nbsp;postaci autorskiego oprogramowania (platformy), służącej Klientowi do ułatwienia zebrania
                    niezbędnych informacji oraz sporządzenia dokumentów niezbędnych do opracowania i&nbsp;wygenerowania
                    wniosku o&nbsp;ogłoszenie upadłości samodzielnie przez Klienta. Aktualna cena Zakupu dostępu do
                    Produktu elektronicznego wynosi 2160 PLN.
                </li>
                <li>Ww. usługi są świadczone w&nbsp;zakresie opisanym bezpośrednio w&nbsp;Serwisie Internetowym.
                </li>
                <li>Świadczenie Usług Elektronicznych na rzecz Klientów w&nbsp;Serwisie internetowym odbywa się na
                    warunkach określonych w&nbsp;Regulaminie.
                </li>
                <li>Korzystanie z&nbsp;Usług elektronicznych związane jest z&nbsp;przesyłaniem danych przy pomocy sieci
                    Internet, co obarczone jest ryzykiem charakterystycznym dla tej sieci.
                </li>
                <li>Zabrania się przekazywania treści o&nbsp;charakterze bezprawnym i&nbsp;nielegalnym, w&nbsp;tym
                    w&nbsp;szczególności materiałów promujących terroryzm, ukazujących wykorzystywanie seksualne dzieci,
                    propagujących rasizm i&nbsp;ksenofobię, a&nbsp;także naruszających prawa własności intelektualnej.
                    Szczegółowe informacje na temat niedozwolonych treści znajdują się w&nbsp;paragrafie "Ograniczenia
                    dotyczące treści –&nbsp;nielegalne treści oraz treści niezgodne z&nbsp;Regulaminem, zgłaszanie
                    nielegalnych treści, punkt kontaktowy" niniejszego Regulaminu.

                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§6 Zasady współpracy oraz składania zamówień w&nbsp;Serwisie</h2>
            <ol class="list-decimal list-outside pl-8 text-justify font-normal flex flex-col gap-2">
                <li>Klientem może być wyłącznie osoba fizyczna posiadająca swój ośrodek interesów życiowych na
                    terytorium Rzeczypospolitej Polskiej.
                </li>
                <li>Klient może korzystać z&nbsp;Serwisu 24 godziny na dobę przez 7&nbsp;dni w&nbsp;tygodniu. Właściciel
                    Serwisu zastrzega sobie możliwość czasowego wyłączenia Serwisu z&nbsp;przyczyn technicznych.
                </li>
                <li>Właściciel Serwisu korzysta z&nbsp;usług zewnętrznych operatorów płatności do oferowania płatności
                    on-line.
                </li>
                <li>Klient może wybrać następujące formy płatności za zamówiony Produkt elektroniczny:
                    <ol class="list-[lower-alpha] list-outside pl-4 text-justify font-normal">
                        <li>przelewem elektronicznym, w&nbsp;tym poprzez BLIK –&nbsp;za pośrednictwem: Przelewy24.
                            W&nbsp;celu dokonania płatności Klient zostanie przeniesiony na stronę serwisu na zasadach
                            wskazanych przez ten serwis.
                        </li>
                    </ol>
                </li>
                <li>Klient ma obowiązek dokonać płatności niezwłocznie po złożeniu zamówienia.
                </li>
                <li>W&nbsp;celu zakupu Produktów przez Serwis należy:
                    <ol class="list-[lower-alpha] list-outside pl-4 text-justify font-normal">
                        <li>założyć konto w&nbsp;Serwisie, kliknąć w&nbsp;link aktywacyjny wysłany na podany podczas
                            rejestracji adres e-mail, zalogować się na swoje konto;
                        </li>
                        <li>wypełnić ankietę kwalifikującą do ogłoszenia upadłości;
                        </li>
                        <li>jeśli Klient nie kwalifikuje się do ogłoszenia upadłości, Właściciel Serwisu zaprasza do
                            umówienia się na bezpłatną konsultację lub do kontaktu telefonicznego;
                        </li>
                        <li>jeśli Klient kwalifikuje się do ogłoszenia upadłości, proponowany jest mu płatny dostęp do
                            Produktu elektronicznego w&nbsp;postaci autorskiego oprogramowania (platformy), w&nbsp;tym
                            celu należy zaakceptować Regulamin a&nbsp;także dokonać płatności za dostęp do platformy
                            zgodnie z&nbsp;wybranym sposobem płatności. Po złożeniu zamówienia, Właściciel Serwisu
                            prześle na podanego e-maila potwierdzenie złożenia zamówienia.
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§7 Produkt elektroniczny -&nbsp;realizacja zamówienia</h2>
            <ol class="list-decimal list-outside pl-8 text-justify font-normal flex flex-col gap-2">
                <li>W&nbsp;przypadku zakupu Produktu uwzględniającego Produkt elektroniczny, dostęp do Produktu zostanie
                    udzielony Klientowi niezwłocznie po dokonaniu skutecznej płatności, nie później niż w&nbsp;terminie
                    48 godzin.
                </li>
                <li>W&nbsp;ramach Konta Użytkownika zostanie udostępniony produkt elektroniczny z&nbsp;zastrzeżeniem
                    postanowień poniżej.
                </li>
                <li>Dostęp do Produktu elektronicznego jest ograniczony czasowo na okres jednego roku. Jednorazowa
                    opłata za dostęp do Produktu elektronicznego nie jest pobierana automatycznie po upływie tego
                    okresu. Klient może ponownie uzyskać dostęp do Produktu elektronicznego odpłatnie, po ponownej
                    kwalifikacji za pomocą ankiety.
                </li>
                <li>W&nbsp;przypadku gdy Klient nie może uruchomić udostępnianego Produktu, powinien niezwłocznie
                    skontaktować się z&nbsp;Właścicielem Serwisu.
                </li>
                <li>Klient jest informowany przez Właściciela Serwisu o&nbsp;aktualizacjach, w&nbsp;tym dotyczących
                    zabezpieczeń niezbędnych do zachowania zgodności Produktu z&nbsp;umową.
                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§8 Warunki techniczne</h2>
            <ol class="list-decimal list-outside pl-8 text-justify font-normal flex flex-col gap-2">
                <li>Klient może korzystać z&nbsp;Serwisu internetowego zgodnie z&nbsp;obowiązującymi przepisami prawa
                    i&nbsp;Regulaminem.
                </li>
                <li>Właściciel Serwisu oświadcza, iż publiczny charakter sieci Internet i&nbsp;korzystanie z&nbsp;usług
                    świadczonych drogą elektroniczną wiązać może się z&nbsp;zagrożeniem pozyskania i&nbsp;modyfikowania
                    danych Klientów przez osoby nieuprawnione, dlatego Klienci powinni stosować właściwe środki
                    techniczne, które zminimalizują wskazane wyżej zagrożenia.
                </li>
                <li>W&nbsp;celu skorzystania z&nbsp;Serwisu lub złożenia zamówienia, konieczne jest posiadanie przez
                    Klienta:
                    <ol class="list-[lower-alpha] list-outside pl-4 text-justify font-normal">
                        <li>aktualnej wersji przeglądarki internetowej wspieranej przez producenta z&nbsp;dostępem do
                            Internetu (np. Opera, Mozilla Firefox, Google Chrome);
                        </li>
                        <li>aktywnego konta poczty elektronicznej.
                        </li>
                    </ol>
                </li>
                <li>W&nbsp;celu skorzystania z&nbsp;Produktów, konieczne jest posiadanie przez Klienta:
                    <ol class="list-[lower-alpha] list-outside pl-4 text-justify font-normal">
                        <li>aktualnej wersji przeglądarki internetowej wspieranej przez producenta z&nbsp;dostępem do
                            Internetu (np. Opera, Mozilla Firefox, Google Chrome);
                        </li>
                        <li>aktywnego konta poczty elektronicznej;
                        </li>
                        <li>aktualnego narzędzia/programu obsługującego pliki elektroniczne w&nbsp;formacie *.zip,
                            *.jpg, *jpeg, *.png, *.pdf, *.mobi, *.pub, *.doc, *.docx, *.xsl;
                        </li>
                        <li>zalecana minimalna rozdzielczość ekranu: 1024x768;
                        </li>
                        <li>włączenie w&nbsp;przeglądarce internetowej możliwości zapisu plików Cookies oraz obsługi
                            Javascript.
                        </li>
                    </ol>
                </li>
                <li>W&nbsp;przypadku gdy do skorzystania z&nbsp;Serwisu lub Produktów konieczne będzie spełnienie
                    dodatkowych wymagań technicznych, Klient zostanie o&nbsp;tym poinformowany przed skorzystaniem
                    z&nbsp;Serwisu lub przed złożeniem zamówienia na Produkt.
                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§9 Reklamacja</h2>
            <ol class="list-decimal list-outside pl-8 text-justify font-normal flex flex-col gap-2">
                <li>Niniejszy rozdział określa uprawnienia Konsumenta oraz Przedsiębiorcy na prawach konsumenta
                    w&nbsp;przypadku umów na dostarczenie treści/usługi cyfrowej zawartych od dnia 1&nbsp;stycznia 2023
                    r. Szczegółowe informacje dot. uprawnień Klienta określają przepisy ustawy o&nbsp;prawach
                    konsumenta, a&nbsp;niniejszy Regulamin nie ma na celu ich ograniczenia lub zmiany.
                </li>
                <li>Klient nie może odstąpić od umowy, jeżeli treść cyfrowa lub usługa cyfrowa są dostarczane
                    w&nbsp;zamian za zapłatę ceny, a&nbsp;brak zgodności z&nbsp;umową jest nieistotny.
                </li>
                <li>Jeżeli Klient nie otrzymał treści lub usługi cyfrowej, Klient informuje o&nbsp;tym Właściciela
                    Serwisu. W&nbsp;przypadku ich niedostarczenia niezwłocznie lub w&nbsp;dodatkowym, wyraźnie
                    uzgodnionym przez strony terminie, Klient może odstąpić od umowy.
                </li>
                <li>Klient może odstąpić od umowy bez wzywania do dostarczenia treści cyfrowej lub usługi cyfrowej,
                    jeżeli:
                    <ol class="list-[lower-alpha] list-outside pl-4 text-justify font-normal">
                        <li>Właściciela Serwisu oświadczył lub z&nbsp;okoliczności wyraźnie wynika, że nie dostarczy
                            treści cyfrowej lub usługi cyfrowej lub
                        </li>
                        <li>Strony uzgodniły lub z&nbsp;okoliczności zawarcia umowy wyraźnie wynika, że określony termin
                            dostarczenia treści cyfrowej lub usługi cyfrowej miał istotne znaczenie dla Klienta,
                            a&nbsp;Właściciela Serwisu nie dostarczył jej w&nbsp;tym terminie.
                        </li>
                    </ol>
                </li>
                <li>Podczas korzystania z&nbsp;Produktu elektronicznego, t.j. platformy subskrybowanej odpłatnie, Klient
                    wykorzystuje dedykowany formularz na platformie do zgłaszania problemów związanych z&nbsp;bieżącym
                    działaniem platformy.
                </li>
                <li>Zgłoszenia reklamacji związanej z&nbsp;dostępem lub działaniem Produktu elektronicznego, są
                    rozpatrywane niezwłocznie, jednak nie dłużej niż w&nbsp;ciągu 48 godzin od zgłoszenia.
                </li>
                <li>W&nbsp;przypadku problemów z&nbsp;dostępem do Produktu elektronicznego, Klient zgłasza to drogą
                    mailową na adres: biuro.arkancelaria@gmail.com
                </li>
                <li>Pozostałe reklamacje Klient składa drogą mailową na adres: biuro.arkancelaria@gmail.com
                </li>
                <li>Rozpatrzenie reklamacji niezwiązanych z&nbsp;dostępem lub działaniem Produktu elektronicznego,
                    następuje w&nbsp;terminie do 30 dni od zgłoszenia.

                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§10 Odstąpienie od umowy</h2>
            <ol class="list-decimal list-outside pl-8 text-justify font-normal flex flex-col gap-2">
                <li>Niniejszy rozdział określa zasady odstąpienia od umowy przez Konsumenta oraz Przedsiębiorcy na
                    prawach konsumenta.
                </li>
                <li>Klient będący Konsumentem lub Przedsiębiorcą działającym na prawach konsumenta ma prawo odstąpić od
                    umowy w&nbsp;terminie 14 dni z&nbsp;zastrzeżeniem postanowień poniżej. W&nbsp;celu skorzystania
                    z&nbsp;prawa do odstąpienia od umowy, Klient powinien poinformować Właściciela Serwisu o&nbsp;tym
                    w&nbsp;drodze jednoznacznego oświadczenia, wysyłając np. e-mail lub pismo na adres wskazany
                    w&nbsp;Regulaminie. Wzór formularza odstąpienia od umowy znajduje się w&nbsp;na końcu Regulaminu.
                </li>
                <li>Prawo do odstąpienia od umowy nie przysługuje w&nbsp;przypadku niektórych umów, o&nbsp;czym Klient
                    zostanie poinformowany przed złożeniem zamówienia:
                    <ol class="list-[lower-alpha] list-outside pl-4 text-justify font-normal">
                        <li>O&nbsp;dostarczanie treści cyfrowych niedostarczanych na nośniku materialnym, za które
                            konsument jest zobowiązany do zapłaty ceny, jeżeli przedsiębiorca rozpoczął świadczenie za
                            wyraźną i&nbsp;uprzednią zgodą konsumenta, który został poinformowany przed rozpoczęciem
                            świadczenia, że po spełnieniu świadczenia przez przedsiębiorcę utraci prawo odstąpienia od
                            umowy i&nbsp;przyjął to do wiadomości, a&nbsp;przedsiębiorca przekazał konsumentowi dokument
                            potwierdzający przyjęcie zapłaty ceny, a&nbsp;zatem zawarcia umowy.
                        </li>
                    </ol>
                </li>
                <li>W&nbsp;przypadku odstąpienia od umowy o&nbsp;dostarczanie treści cyfrowej lub usługi cyfrowej,
                    Klient powstrzymuje się od korzystania z&nbsp;tej treści cyfrowej lub usługi cyfrowej
                    i&nbsp;udostępniania ich osobom trzecim.
                </li>
                <li>Możliwe koszty związane z&nbsp;odstąpieniem przez konsumenta od umowy, które obowiązany jest
                    ponieść:
                    • W&nbsp;przypadku Usługi, której wykonywanie -&nbsp;na wyraźne żądanie konsumenta -&nbsp;rozpoczęło
                    się przed upływem terminu do odstąpienia od umowy, konsument, który wykonuje prawo odstąpienia od
                    umowy po zgłoszeniu takiego żądania, ma obowiązek zapłaty za świadczenia spełnione do chwili
                    odstąpienia od umowy w&nbsp;pełnej wysokości.

                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§11 Zwroty –&nbsp;skutki odstąpienia od umowy</h2>
            <ol class="list-decimal list-outside pl-8 text-justify font-normal flex flex-col gap-2">
                <li>W&nbsp;przypadku odstąpienia przez Klienta od umowy, za wyjątkiem przypadku określonego w&nbsp;§10,
                    pkt 3a, Właściciel Serwisu ma obowiązek niezwłocznie, jednak nie później niż w&nbsp;ciągu czternastu
                    dni od dnia otrzymania powiadomienia o&nbsp;odstąpieniu od umowy, zwrócić Klientowi wszystkie
                    otrzymane od Klienta płatności.
                </li>
                <li>Do zwrotu płatności stosuje się ten sam środek płatności, którym posłużył się Klient podczas
                    pierwotnej transakcji, chyba że jednoznacznie zostanie uzgodniony z&nbsp;Klientem inny środek
                    płatności.
                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§12 Panel użytkownika</h2>
            <ol class="list-decimal list-outside pl-8 text-justify font-normal flex flex-col gap-2">
                <li>Właściciel Serwisu może utworzyć Klientowi Konto Użytkownika, tj. indywidualny panel uruchomiony na
                    rzecz Klienta przez Właściciela Serwisu w&nbsp;celu korzystania z&nbsp;Produktów Właściciela Serwisu
                    po dokonaniu przez Klienta rejestracji i&nbsp;zawarciu nieodpłatnej umowy na utworzenie
                    i&nbsp;prowadzenie Konta Użytkownika, dalej jako Konto.
                </li>
                <li>Klient może utworzyć Konto Użytkownika po dokonaniu rejestracji w&nbsp;Serwisie, dalej jako Konto.
                    Umowa na utworzenie i&nbsp;prowadzenie Konta Użytkownika jest zawarta na czas nieokreślony.
                </li>
                <li>Klient nie może posiadać kilku Kont Użytkownika lub udostępniać Konta Użytkownika osobom trzecim.
                </li>
                <li>Właściciel Serwisu przesyła na wskazany przez Klienta adres e-mailowy informacje dotyczące Konta
                    Użytkownika. Klient ustanawia indywidualne hasło do Konta. Klient ma obowiązek ustanowić
                    indywidualne hasło również w&nbsp;przypadku, gdy na potrzeby rejestracji Konta Użytkownika hasło
                    generowane jest automatycznie przez system. Po rejestracji Konta, Klient powinien niezwłocznie
                    ustanowić nowe hasło.
                </li>
                <li>Klient może zgłosić żądanie usunięcia Konta Użytkownika Właścicielowi Serwisu e-mailowo lub
                    w&nbsp;inny sposób przyjęty do komunikacji z&nbsp;Właścicielem Serwisu z&nbsp;zachowaniem
                    14-dniowego okresu wypowiedzenia bez podania przyczyny.
                </li>
                <li>Usunięcie Konta Użytkownika wiąże się z&nbsp;utratą dostępu do Produktów udostępnionych
                    w&nbsp;ramach Konta Użytkownika.
                </li>
                <li>Właściciel Serwisu może wypowiedzieć umowę na utworzenie i&nbsp;prowadzenie Konta Użytkownika:
                    <ol class="list-[lower-alpha] list-outside pl-4 text-justify font-normal">
                        <li>z&nbsp;ważnych przyczyn z&nbsp;zachowaniem 14-dniowego terminu wypowiedzenia (dotyczy
                            Klienta będącego Konsumentem lub Przedsiębiorcą na prawach konsumenta). Pod pojęciem ważnej
                            przyczyny należy rozumieć w&nbsp;szczególności naruszenie przez Klienta postanowień
                            Regulaminu lub przepisów prawnych, a&nbsp;także podejmowanie przez Klienta działań
                            sprzecznych z&nbsp;dobrymi obyczajami;
                        </li>
                        <li>bez podania przyczyny w&nbsp;trybie natychmiastowym (dotyczy Klienta, który nie jest
                            Konsumentem lub Przedsiębiorcą na prawach konsumenta).
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§14 Prawa autorskie i&nbsp;licencje</h2>
            <ol class="list-decimal list-outside pl-8 text-justify font-normal flex flex-col gap-2">
                <li>Wszelkie materiały udostępniane przez Właściciela Serwisu, w&nbsp;tym Produkty, zdjęcia, teksty,
                    grafiki, multimedia oraz znaki towarowe są utworem w&nbsp;rozumieniu ustawy o&nbsp;prawie autorskim
                    i&nbsp;prawach pokrewnych, podlegającym ochronie prawnej.
                </li>
                <li>Prawami autorskimi do ww. materiałów dysponuje Właściciel Serwisu bądź inny podmiot, od którego
                    Właściciel Serwisu uzyskał odpowiednią licencję. Materiały mogą być też wykorzystywane przez
                    Właściciela Serwisu w&nbsp;oparciu o&nbsp;inną podstawę prawną.
                </li>
                <li>Wszelkie materiały udostępniane przez Właściciela Serwisu mogą być wykorzystywane wyłącznie przez
                    Klienta na własny użytek. Nieuprawnione jest dalsze rozpowszechnianie, udostępnianie, zgrywanie
                    i&nbsp;pobieranie w&nbsp;jakikolwiek sposób materiałów poza zakresem dozwolonego użytku.
                </li>
                <li>Właściciel Serwisu udziela Klientowi niewyłącznej licencji, bez prawa do udzielania sublicencji oraz
                    bez ograniczeń terytorialnych. Wynagrodzenie z&nbsp;tytułu udzielenia licencji zostało uwzględnione
                    w&nbsp;cenie.
                </li>
                <li>W&nbsp;przypadku naruszenia zakazu, o&nbsp;którym mowa w&nbsp;niniejszym paragrafie, w&nbsp;tym
                    naruszenia praw autorskich, Właściciel Serwisu ma prawo domagać się od Klienta odszkodowania
                    i&nbsp;zadośćuczynienia. Klient w&nbsp;ww. zakresie może ponosić odpowiedzialność cywilną lub karną.
                </li>
                <li>Właściciel Serwisu ma prawo do dokonania okresowej aktualizacji Produktów.
                </li>
                <li>Do niniejszego Regulaminu zastosowanie mają przepisy RP.
                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§15 Postanowienia końcowe</h2>
            <ol class="list-decimal list-outside pl-8 text-justify font-normal flex flex-col gap-2">
                <li>Podczas trwania siły wyższej, Strony umowy zwolnione będą od wszelkiej odpowiedzialności za jej
                    niewykonanie lub nienależyte wykonanie, jeżeli tylko okoliczności zaistnienia siły wyższej będą
                    stanowiły przeszkodę w&nbsp;wykonaniu umowy. Powyższe ma zastosowanie również w&nbsp;okresie
                    bezpośrednio poprzedzającym lub następującym bezpośrednio po wystąpieniu siły wyższej, jeżeli tylko
                    we wskazanym okresie oddziaływanie siły wyższej będzie stanowiło przeszkodę w&nbsp;wykonaniu umowy.
                </li>
                <li>Przez „siłę wyższą” należy rozumieć zdarzenie o&nbsp;charakterze nagłym lub naturalnym, niezależne
                    od woli i&nbsp;działania Stron, którego nie można było przewidzieć i&nbsp;niemożliwe było jego
                    zapobieżenie, w&nbsp;szczególności takie zdarzenia jak: powódź, wojna, akt terroru, wprowadzenie
                    stanu wyjątkowego.
                </li>
                <li>W&nbsp;ramach korzystania z&nbsp;Produktów zabrania się działania w&nbsp;sposób sprzeczny
                    z&nbsp;prawem, dobrymi obyczajami lub naruszający dobra osobiste osób trzecich oraz dostarczania
                    informacji o&nbsp;charakterze bezprawnym.
                </li>
                <li>Polubowne rozpatrywanie sporów i&nbsp;rozpatrywania reklamacji. Konsument ma możliwość zwrócenia się
                    do:
                    <ol class="list-[lower-alpha] list-outside pl-4 text-justify font-normal">
                        <li>stałego polubownego sądu konsumenckiego z&nbsp;wnioskiem o&nbsp;rozstrzygnięcie sporu
                            wynikłego z&nbsp;zawartej umowy;
                        </li>
                        <li>wojewódzkiego inspektora Inspekcji Handlowej z&nbsp;wnioskiem o&nbsp;wszczęcie postępowania
                            mediacyjnego, w&nbsp;sprawie polubownego zakończenia sporu między Klientem
                            a&nbsp;Właścicielem Serwisu;
                        </li>
                        <li>powiatowego (miejskiego) rzecznika praw konsumenta lub organizacji społecznej, do której
                            statutowych zadań należy ochrona konsumentów w&nbsp;celu uzyskania pomocy w&nbsp;sprawie
                            umowy;
                        </li>
                        <li>lub ma prawo skorzystać z&nbsp;platformy ODR. Platforma służy rozstrzyganiu sporów pomiędzy
                            konsumentami i&nbsp;przedsiębiorcami <a
                                style="color: var(--p-primary-500);text-decoration:underline;"
                                href="http://ec.europa.eu/consumers/odr">
                                http://ec.europa.eu/consumers/odr
                            </a>.
                        </li>
                    </ol>
                </li>
                <li>Właściciel Serwisu zastrzega sobie prawo do wprowadzenia zmian do Regulaminu z&nbsp;ważnych
                    przyczyn, w&nbsp;tym w&nbsp;szczególności z&nbsp;powodu zmiany przepisów prawa w&nbsp;zakresie,
                    w&nbsp;jakich zmiany te wymuszają na Właścicielowi Serwisu również zmiany treści niniejszego
                    Regulaminu, w&nbsp;szczególności zmiany przepisów Kodeksu cywilnego, ustawy o&nbsp;prawach
                    konsumenta, ustawy o&nbsp;świadczeniu usług drogą elektroniczną, a&nbsp;także na mocy obowiązujących
                    decyzji UOKIK, PUODO lub orzeczeń sądowych w&nbsp;zakresie odpowiadającym wydanym
                    decyzjom/orzeczeniom oraz w&nbsp;przypadku istotnej zmiany czynników biznesowych, o&nbsp;ile
                    istnieje związek przyczynowo- skutkowy między ww. zmianą a&nbsp;zmianą kosztów świadczenia usług
                    przez Właściciela Serwisu.
                </li>
                <li>Prawem właściwym jest prawo polskie z&nbsp;zastrzeżeniem ust. 9.
                </li>
                <li>Sądem właściwym jest sąd polski z&nbsp;zastrzeżeniem ust. 9.
                </li>
                <li>W&nbsp;przypadku Klienta będącego konsumentem, postanowienia Regulaminu nie pozbawiają konsumenta
                    ochrony przyznanej przez przepisy prawa państwa jego zwykłego pobytu, których nie da się wyłączyć na
                    podstawie umowy. W&nbsp;przypadku gdy przepisy, które obowiązują w&nbsp;państwie konsumenta są dla
                    niego bardziej korzystne, a&nbsp;przepisów tych nie można wyłączyć w&nbsp;drodze umowy, to znajdą
                    one zastosowanie w&nbsp;umowie zawartej między Klientem a&nbsp;Właścicielem Serwisu.
                </li>
                <li>Zasady dotyczące przetwarzania danych osobowych uregulowane są w&nbsp;Polityce prywatności.
                </li>
                <li>Regulamin obowiązuje od dnia 01.01.2025r.
                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§16 Pozostałe postanowienia dotyczące przedsiębiorców</h2>
            <ol class="list-decimal list-outside pl-8 text-justify font-normal flex flex-col gap-2">
                <li>Postanowienia z&nbsp;niniejszego paragrafu mają zastosowanie do Przedsiębiorcy, który nie jest
                    Przedsiębiorcą na prawach konsumenta.
                </li>
                <li>Właścicielowi Serwisu przysługuje prawo odstąpienia od umowy zawartej z&nbsp;Klientem niebędącym
                    konsumentem w&nbsp;dowolnym terminie od dnia jej zawarcia. Odstąpienie od umowy w&nbsp;tym wypadku
                    może nastąpić bez podania przyczyny i&nbsp;nie rodzi po stronie Klienta niebędącego konsumentem
                    żadnych roszczeń w&nbsp;stosunku do Właściciela Serwisu.

                </li>
                <li>W&nbsp;przypadku Klientów niebędących konsumentami Właściciel Serwisu ma prawo ograniczyć dostępne
                    sposoby płatności, w&nbsp;tym także wymagać dokonania przedpłaty w&nbsp;całości albo części
                    i&nbsp;to niezależnie od wybranego przez Klienta sposobu płatności.

                </li>
                <li>Zgodnie z&nbsp;art. 558 §1 Kodeksu cywilnego odpowiedzialność Właściciela Serwisu z&nbsp;tytułu
                    rękojmi za Usługę lub Usługi wobec Klienta niebędącego konsumentem zostaje wyłączona.

                </li>
                <li>Sądem właściwym do rozstrzygania sporów powstałych pomiędzy Właścicielem Serwisu
                    a&nbsp;Przedsiębiorcą, który nie jest Przedsiębiorcą na prawach konsumenta jest sąd właściwy ze
                    względu na siedzibę Powoda.
                </li>
                <li>Właściciel Serwisu ma prawo wypowiedzieć Przedsiębiorcy, który nie jest Przedsiębiorcą na prawach
                    konsumenta umowę w&nbsp;trybie natychmiastowym. W&nbsp;tym celu Właściciel Serwisu przesyła
                    Przedsiębiorcy na adres e-mailowy lub adres korespondencyjny oświadczenie w&nbsp;przedmiocie
                    wypowiedzenia umowy. Przedsiębiorca zrzeka się jakichkolwiek roszczeń w&nbsp;tym zakresie.
                </li>
                <li>Odpowiedzialność Właściciela Serwisu w&nbsp;stosunku do Klienta niebędącego konsumentem, bez względu
                    na jej podstawę prawną jest ograniczona -&nbsp;zarówno w&nbsp;ramach pojedynczego roszczenia, jak
                    również za wszelkie roszczenia w&nbsp;sumie -&nbsp;do wysokości zapłaconej ceny z&nbsp;tytułu umowy,
                    nie więcej jednak niż do kwoty jednego tysiąca złotych. Właściciel Serwisu ponosi odpowiedzialność
                    w&nbsp;stosunku do Klienta niebędącego konsumentem tylko za typowe szkody przewidywalne
                    w&nbsp;momencie zawarcia umowy i&nbsp;nie ponosi odpowiedzialności z&nbsp;tytułu utraconych korzyści
                    w&nbsp;stosunku do Klienta niebędącego konsumentem.

                </li>
                <li>Właściciel Serwisu nie odpowiada za czasowe wyłączenie z&nbsp;użytku serwerów.

                </li>
            </ol>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="font-bold">§ 17 Ograniczenia dotyczące treści –&nbsp;nielegalne treści oraz treści niezgodne
                z&nbsp;Regulaminem, zgłaszanie nielegalnych treści, punkt kontaktowy</h2>
            <ol class="list-decimal list-outside pl-8 text-justify font-normal flex flex-col gap-2">
                <li>Klient zobowiązany jest do korzystania z&nbsp;Serwisu w&nbsp;sposób zgodny z&nbsp;prawem
                    i&nbsp;etyką, z&nbsp;poszanowaniem dóbr osobistych oraz praw autorskich i&nbsp;własności
                    intelektualnej Właściciela i&nbsp;innych osób.
                </li>
                <li>Zabronione jest umieszczanie treści niezgodnych z&nbsp;prawem.
                </li>
                <li>Treści nie mogą promować, aprobować ani przedstawiać (w tym poprzez linki do innych stron
                    internetowych) popełniania przestępstw określonych w&nbsp;Dyrektywie 2017/541 (dalej jako:
                    "Dyrektywa"), takich jak m.in.:
                    <ol class="list-[lower-alpha] list-outside pl-4 text-justify font-normal">
                        <li>Handel ludźmi, tj.: werbowanie, transport, przekazywanie, przechowywanie lub przyjmowanie
                            osób;
                            Stosowanie groźby, przemocy, przymusu, uprowadzenia, oszustwa, podstępu;
                            Nadużywanie uprawnień, wykorzystywanie sytuacji bezbronności;
                            Wręczenie lub przyjmowanie płatności lub korzyści dla uzyskania zgody osoby sprawującej
                            kontrolę nad inną osobą, w&nbsp;celu wyzysku.
                        </li>
                        <li>Przestępstwa związane z&nbsp;terroryzmem (pełny katalog przestępstw wymieniony w&nbsp;art.
                            3&nbsp;ust. 1&nbsp;Dyrektywy):
                            Popełnione w&nbsp;celu zastraszenia ludności, bezprawnego zmuszenia rządu lub organizacji
                            międzynarodowej do podjęcia lub zaniechania jakiegoś działania, lub destabilizacji lub
                            zniszczenia podstawowych struktur politycznych, konstytucyjnych, gospodarczych lub
                            społecznych danego państwa lub organizacji międzynarodowej.
                        </li>
                        <li>Przykładowe przestępstwa to:
                            <br>-&nbsp;Ataki na życie ludzkie mogące powodować śmierć.
                            <br>-&nbsp;Ataki na integralność fizyczną osoby.
                            <br>-&nbsp;Porwania lub branie zakładników.
                            <br>-&nbsp;Wytwarzanie, posiadanie, nabywanie, przewożenie, dostarczanie lub używanie materiałów
                            wybuchowych lub broni (w tym chemicznej, biologicznej, radiologicznej lub jądrowej), badania
                            nad taką bronią i&nbsp;rozwój broni.
                            <br>-&nbsp;Nielegalna ingerencja w&nbsp;systemy informatyczne, powodująca poważne szkody lub
                            popełniona przeciwko systemowi informatycznemu o&nbsp;charakterze infrastruktury krytycznej.
                            <br>-&nbsp;Nielegalna ingerencja w&nbsp;dane komputerowe w&nbsp;systemie informatycznym
                            o&nbsp;charakterze infrastruktury krytycznej.
                            <br>-&nbsp;Groźby popełnienia jednego z&nbsp;powyższych czynów.
                        </li>
                        <li>Rozpowszechnianie lub udostępnianie przekazów do publicznej wiadomości z&nbsp;zamiarem
                            podżegania do popełnienia przestępstwa wymienionych w&nbsp;Dyrektywie, jeżeli takie
                            działanie popiera popełnienie przestępstw terrorystycznych i&nbsp;stwarza niebezpieczeństwo
                            ich popełnienia.
                        </li>
                        <li>Nakłanianie innych osób do popełnienia lub przyczynienia się do popełnienia przestępstw
                            wymienionych w&nbsp;Dyrektywie.
                        </li>
                        <li>Dostarczanie lub gromadzenie funduszy, bezpośrednio lub pośrednio, z&nbsp;zamiarem ich
                            wykorzystania do popełnienia przestępstw terrorystycznych.
                        </li>
                        <li>Sporządzanie lub korzystanie z&nbsp;fałszywych dokumentów urzędowych z&nbsp;zamiarem
                            popełnienia przestępstw wymienionych w&nbsp;Dyrektywie.
                        </li>
                    </ol>
                </li>
                <li>Treści nie mogą zawierać informacji, które:
                    <ol class="list-[lower-alpha] list-outside pl-4 text-justify font-normal">
                        <li>Naruszają autorskie prawa osobiste lub majątkowe.
                        </li>
                        <li>Naruszają dobre obyczaje lub normy moralne, obrażają godność lub naruszają dobra osobiste
                            innych osób, w&nbsp;tym prawo do wizerunku.
                        </li>
                        <li>Są wulgarne, obraźliwe, popierają radykalne postawy społeczne lub głoszą takie poglądy (w
                            tym wszelkiego rodzaju dyskryminację rasową, etniczną, ze względu na płeć, wyznanie,
                            propagowanie nienawiści itp.) oraz zawierają treści pornograficzne.
                        </li>
                        <li>Stanowią reklamę zakazaną przepisami ustawy z&nbsp;dnia 16.04.1993 r. o&nbsp;zwalczaniu
                            nieuczciwej konkurencji oraz ustawy z&nbsp;dnia 23.07.2007 r. o&nbsp;przeciwdziałaniu
                            nieuczciwym praktykom rynkowym.
                        </li>
                        <li>Są informacjami handlowymi wprowadzającymi w&nbsp;błąd.
                        </li>
                    </ol>
                </li>
                <li>Właściciel Serwisu przeciwdziała rozpowszechnianiu treści terrorystycznych zgodnie
                    z&nbsp;Rozporządzeniem 2021/784 oraz nielegalnych treści zgodnie z&nbsp;DSA.
                    <ol class="list-[lower-alpha] list-outside pl-4 text-justify font-normal">
                        <li>Właściciel Serwisu utworzył centralny punkt kontaktowy (zwany dalej: Punktem Kontaktowym)
                            przeznaczony do bezpośredniej komunikacji elektronicznej z&nbsp;Portalem dla organów państw
                            członkowskich, Komisji oraz Rady Usług Cyfrowych zgodnie z&nbsp;wymogami DSA w&nbsp;formie
                            skrzynki poczty elektronicznej: oddluzenia.akr@gmail.com. Punkt Kontaktowy obsługuje także
                            nakazy podjęcia działań przeciwko nielegalnym treściom oraz nakazy udzielenia informacji.
                        </li>
                        <li>Punkt Kontaktowy został utworzony w&nbsp;celu spełnienia wymogów art. 11 Rozporządzenia
                            Parlamentu Europejskiego i&nbsp;Rady (UE) 2022/2065 z&nbsp;dnia 19 października 2022 r.,
                            dotyczącego jednolitego rynku usług cyfrowych oraz zmieniającego dyrektywę 2000/31/WE (akt
                            o&nbsp;usługach cyfrowych) (DSA).
                        </li>
                        <li>Punkt Kontaktowy jest odpowiedzialny za obsługę nakazów związanych z&nbsp;działaniami
                            przeciwko nielegalnym treściom zgodnie z&nbsp;art. 9&nbsp;DSA oraz nakazów udzielenia
                            informacji na podstawie art. 10 DSA, które są przekazywane do Właściciela przez odpowiednie
                            organy sądowe i&nbsp;administracyjne.
                        </li>
                        <li>Użytkownicy mogą również korzystać z&nbsp;Punktu Kontaktowego do bezpośredniej
                            i&nbsp;szybkiej komunikacji z&nbsp;Właścicielem Serwisu.
                        </li>
                        <li>Punkt Kontaktowy obsługuje komunikację w&nbsp;języku polskim lub angielskim.
                        </li>
                        <li>Zgłoszenia dotyczące nielegalnych treści należy kierować do Właściciela Serwisu wyłącznie za
                            pomocą dedykowanego Punktu Kontaktowego dostępnego w&nbsp;Serwisie. Zgłoszenie musi zawierać
                            wszystkie wymagane informacje wskazane w&nbsp;formularzu (załącznik nr 2&nbsp;na końcu
                            Regulaminu).
                        </li>
                        <li>Właściciel Serwisu poinformuje zgłaszającego o&nbsp;otrzymaniu zgłoszenia oraz
                            o&nbsp;sposobie jego rozpatrzenia za pośrednictwem poczty elektronicznej.
                        </li>
                        <li>Właściciel Serwisu nie przegląda samodzielnie publikowanych treści.
                        </li>
                        <li>Skargi dotyczące działań Właściciela Serwisu związanych z&nbsp;treściami terrorystycznymi
                            lub nielegalnymi należy składać zgodnie z&nbsp;procedurą opisaną w&nbsp;Regulaminie.
                        </li>
                    </ol>
                </li>
                <li>Właściciel Serwisu zawiesza świadczenie usług na rozsądny okres po uprzednim ostrzeżeniu
                    w&nbsp;przypadku odbiorców usługi, którzy często przekazują ewidentnie nielegalne treści. Ponadto,
                    Sprzedawca zawiesza na rozsądny okres, po wcześniejszym ostrzeżeniu, rozpatrywanie zgłoszeń
                    dokonywanych za pośrednictwem mechanizmów zgłaszania i&nbsp;działania oraz skarg wnoszonych za
                    pośrednictwem wewnętrznych systemów rozpatrywania skarg, o&nbsp;których mowa odpowiednio w&nbsp;art.
                    16 i&nbsp;20 DSA, przez osoby lub podmioty często składające ewidentnie bezzasadne zgłoszenia lub
                    skargi.
                </li>
                <li>Właściciel Serwisu podejmuje decyzje o&nbsp;zawieszeniu usług po dokładnej ocenie każdego przypadku,
                    terminowo i&nbsp;obiektywnie, z&nbsp;należytą starannością. Sprawdza, czy odbiorca usługi, osoba,
                    podmiot lub skarżący dopuszcza się nadużycia, o&nbsp;którym mowa w&nbsp;pkt 6, uwzględniając
                    wszystkie dostępne istotne fakty i&nbsp;okoliczności. Okoliczności te obejmują co najmniej:
                    <ol class="list-[lower-alpha] list-outside pl-4 text-justify font-normal">
                        <li>Liczbę ewidentnie nielegalnych treści lub bezzasadnych zgłoszeń lub skarg, które zostały
                            przekazane, złożone lub wniesione w&nbsp;danym okresie.
                        </li>
                        <li>Proporcję tych liczby w&nbsp;stosunku do całkowitej liczby przekazanych informacji lub
                            zgłoszeń dokonanych w&nbsp;danym okresie.
                        </li>
                        <li>Ciężar nadużycia, w&nbsp;tym charakter nielegalnych treści i&nbsp;ich konsekwencje.
                        </li>
                        <li>Zamiar odbiorcy usługi, osoby, podmiotu lub skarżącego, jeśli można go ustalić.
                        </li>
                    </ol>
                </li>
                <li>W&nbsp;zakresie nieuregulowanym zastosowanie mają postanowienia zawarte w&nbsp;DSA.

                </li>
            </ol>
        </div>


        <div class="flex flex-col gap-4">
            <b>Załącznik nr 1.</b>
            WZÓR FORMULARZA ODSTĄPIENIA OD UMOWY<br>
            Wypełnij formularz w&nbsp;przypadku chęci odstąpienia od umowy.<br>
            <div class="flex flex-col justify-center items-center w-full gap-4">
                <div class="rounded border p-4 w-full lg:w-7/12">
                    Data i&nbsp;miasto:<br>
                    Twoje imię i&nbsp;nazwisko:<br>
                    Twój adres:<br>
                    Twój e-mail:<br>
                    Twój telefon:<br>
                    <div class="w-full text-right">Upadłość Konsumencka -&nbsp;Kancelaria Adam Rogalski</div>
                    <div class="w-full text-right">Nadbrzeżna 2</div>
                    <div class="w-full text-right">43-300 Bielsko-Biała</div>
                    <div class="w-full text-center font-bold">Odstąpienie od umowy</div>
                    Odstępuję od umowy z&nbsp;dnia:<br>
                    Numer zamówienia:
                </div>
                <Button class="w-fit" @click="downloadForm1">Pobierz formularz</Button>
            </div>
        </div>

        <div class="flex flex-col gap-4">
            <b>Załącznik nr 2.</b>
            ZGŁOSZENIE NIELEGALNYCH TREŚCI<br>
            Wypełnij formularz w&nbsp;przypadku chęci zgłoszenia nielegalnych treści, treści naruszających zasady
            korzystania z&nbsp;usług oraz treści szkodliwych, publikowanych za pomocą platformy upadlosc-ar-expert.pl, w
            tym szczególnie: treści promujących terroryzm, ukazujących wykorzystywanie seksualne dzieci, szerzących
            rasizm i&nbsp;ksenofobię, naruszających prawa własności intelektualnej, cyberstalkingu, sprzedaży produktów
            podrobionych lub niespełniających norm, naruszających prawa ochrony konsumentów, bezprawnego
            wykorzystywania materiałów chronionych prawem autorskim, nielegalnego oferowania usług zakwaterowania
            oraz nielegalnej sprzedaży żywych zwierząt.
            <div class="flex flex-col justify-center items-center w-full gap-4">
                <div class="rounded border p-4 w-full lg:w-7/12">
                    Data i&nbsp;miasto:<br>
                    Twoje imię i&nbsp;nazwisko:<br>
                    Twój adres:<br>
                    Twój e-mail:<br>
                    Twój telefon:<br>
                    <div class="w-full text-right">Upadłość Konsumencka -&nbsp;Kancelaria Adam Rogalski</div>
                    <div class="w-full text-right">Nadbrzeżna 2</div>
                    <div class="w-full text-right">43-300 Bielsko-Biała</div>
                    <div class="w-full text-center font-bold">Zgłoszenie nielegalnych treści</div>
                    Adres lub adresy URL treści, które uważasz za nielegalne:<br>
                    Które z&nbsp;treści na stronie są nielegalne (wskaż dokładnie):<br>
                    Uzasadnij zgłoszenie: Oświadczam, że powzięte w&nbsp;dobrej wierze przekonanie osoby lub podmiotu,
                    w&nbsp;imieniu którego dokonuję
                    zgłoszenia, że informacje i&nbsp;zarzuty w&nbsp;nim zawarte są prawidłowe i&nbsp;kompletne.<br>
                </div>
                <Button class="w-fit" @click="downloadForm2">Pobierz formularz</Button>
            </div>
        </div>
    </div>
</template>

<script setup>
import Button from 'primevue/button';

function downloadForm1() {
    downloadForm("Wzór_odstąpienia_od_umowy.pdf");
}

function downloadForm2() {
    downloadForm("Wzór_zgłoszenie_nielegalnych_treści.pdf");
}

function downloadForm(file) {
    const link = document.createElement('a');
    link.href = '/Auron/' + file;
    link.download = file;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

</script>