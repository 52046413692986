<template>
    <main class="w-full mt-0 pl-3 pr-2 pb-0 pt-3 flex flex-col justify-start items-center gap-3">
        <div class="flex flex-col gap-3 w-full xl:w-10/12 justify-center items-center">
            <Card v-for="(card, index) in cards" ref="cardRefs" class="w-full" :pt="{ content: { class: 'p-0' } }">
                <template #title>
                    <span>{{ card.title }}</span>
                    <Divider class="!my-3" />
                </template>
                <template #content>
                    <component :is="components[card.content]"></component>
                </template>
            </Card>
        </div>
    </main>
</template>

<script setup>
    import { ref, defineExpose, onMounted } from 'vue';
    import Card from 'primevue/card';
    import Divider from 'primevue/divider';
    import policy from '@/components/content/policy.vue';
    import agreement from '@/components/content/agreement.vue';

    const components = {
        policy,
        agreement,
    };

    const cards = ref([
        {
            title: 'Polityka prywatności',
            content: 'policy',
        },
        {
            title: 'Regulamin',
            content: 'agreement',
        },
    ]);

    const cardRefs = ref([]);

    defineExpose({
        scrollToCard(index) {
            const cardElement = cardRefs.value[index];
            if (cardElement && cardElement.$el) {
                cardElement.$el.scrollIntoView({ behavior: 'smooth' });
            }
        }
    });
    
        
</script>
